@use "@picocss/pico/scss/pico";
@import "@picocss/pico/scss/colors";

input[type=button],
button,
div[role=group] {
  outline: none !important;
  box-shadow: none !important;
}

@media (max-width: 450px) {
  div.small-group > button {
    padding: 0.5rem;
  }
}

@media (max-width: 900px) {
  .small-hidden {
    display: none !important;
  }
}

.footer {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 0.5rem;
}

.cookie-consent {
  background-color: var(--pico-background-color);
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: solid 5px $azure-500;
  padding: 0.5rem;
}

.cookie-consent button {
  float: right;
  margin: 0.5rem;
}

button.delete {
  border-color: $red-500;
  background-color: $red-400;
}

button.background {
  border-color: $azure-400;
  background-color: $azure-400;
}

.scrollable {
  border: solid 4px $azure-500;
  border-radius: 0.5rem;
  padding: 0.5rem;
  overflow-y: scroll;
  max-height: 10rem;
}